<template>
  <Layout>
    <PageHeader :title="$t('views.printers.printersMap')"/>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="padding: 0;">
            <div id="map" style="z-index: 0; width: 100%; min-height: 600px; max-height: 1080px; height: 70vh;"/>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
#map .leaflet-attribution-flag {
  display: none !important; /* Скроем флаг рядом с ссылкой на leafletjs.com */
}
</style>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";

export default {

  components: {
    Layout,
    PageHeader,
  },

  methods: {

    /**
     * Подгружает скрипт leaflet
     */
    injectMapsAPIScript() {
      return new Promise((resolve, reject) => {
        const mapsAPIScript = document.createElement('script');
        mapsAPIScript.src = `/libs/leafletjs/leaflet.js`;
        document.head.appendChild(mapsAPIScript);
        mapsAPIScript.onload = () => resolve();
        mapsAPIScript.onerror = () => reject();
      });
    },

    /**
     * Проверяет, загружен ли скрипт leaflet
     */
    isMapsAPIScriptInjected() {
      const headScripts = document.head.getElementsByTagName("script");
      for (const script of headScripts) {
        if (script.src.includes("leaflet")) {
          return true;
        }
      }
      return false;
    },

    /**
     * Создает карту и маркеры на ней
     */
    initMap(printers) {
      // Инициализируем карту внутри элемента #map
      const map = window.L.map('map').setView([ 55.7558, 37.6173 ], 10);

      // Добавим слой тайлов
      window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        tileSize: 256,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);

      // Добавим кастомные иконки
      const iconOk = window.L.icon({
        iconUrl: '/libs/leafletjs/icon-ok.png',
        iconSize: [16, 32],
        iconAnchor: [8, 32],
        popupAnchor: [0, -32]
      });
      const iconSleeping = window.L.icon({
        iconUrl: '/libs/leafletjs/icon-sleep.png',
        iconSize: [16, 32],
        iconAnchor: [8, 32],
        popupAnchor: [0, -32]
      });
      const iconOffline = window.L.icon({
        iconUrl: '/libs/leafletjs/icon-offline.png',
        iconSize: [16, 32],
        iconAnchor: [8, 32],
        popupAnchor: [0, -32]
      });
      const iconWarning = window.L.icon({
        iconUrl: '/libs/leafletjs/icon-warning.png',
        iconSize: [22, 42],
        iconAnchor: [11, 42],
        popupAnchor: [0, -42]
      });
      const iconError = window.L.icon({
        iconUrl: '/libs/leafletjs/icon-error.png',
        iconSize: [22, 42],
        iconAnchor: [11, 42],
        popupAnchor: [0, -42]
      });

      // Добавим на карту маркеры
      for (const printer of printers) {

        const hasErrors = printer.errors.length > 0;
        const hasWarnings = printer.warnings.length > 0;
        const hasProblems = hasWarnings || hasErrors;
        const isOffline = !printer.online;
        const isSleeping = printer.sleeping;

        // - Иконка
        let icon = iconOk;
        if (hasErrors) { icon = iconError; }
        else if (hasWarnings) { icon = iconWarning; }
        else if (isOffline) { icon = iconOffline; }
        else if (isSleeping) { icon = iconSleeping; }

        // - Текст
        let text = '';
        if (hasProblems) {
          if (hasErrors) {
            text += 'Ошибки:<br/>'
                + printer.errors
                    .map((error) => `- ${error}`)
                    .join('<br/>');
            if (hasWarnings) {
              text += '<br/><br/>';
            }
          }
          if (hasWarnings) {
            text += 'Проблемы:<br/>'
                + printer.warnings
                    .map((warning) => `- ${warning}`)
                    .join('<br/>');
          }
        } else if (isOffline) {
          text = 'Принтер офлайн';
        } else if (isSleeping) {
          text = 'Принтер спит';
        } else {
          text = 'Всё в порядке';
        }

        // - Тултип при наведении
        let tooltip = '';
        if (hasErrors) {
          tooltip = 'Есть ошибки';
        } else if (hasWarnings) {
          tooltip = 'Есть проблемы';
        } else if (isOffline) {
          tooltip = 'Принтер офлайн';
        } else if (isSleeping) {
          tooltip = 'Принтер спит';
        } else {
          tooltip = 'Всё в порядке';
        }

        window.L
            .marker([ printer.latitude, printer.longitude ], {
              title: tooltip,
              icon: icon,
              zIndexOffset: hasProblems ? 1000 : 0 // отобразим проблемные принтеры поверх остальных
            })
            .addTo(map)
            .bindPopup(`<span style="font-size: x-small; opacity: 0.75" title="Серийный номер принтера">${printer.serial_number}</span><br><b>${printer.name}</b><br/>${printer.address}<br/><br/>${text}`);
      }
    },

    getPrinters() {
      return new Promise((resolve, reject) => {
        axios
            .post(`/v1/printers/map-get-all`)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
      })
    }
  },

  async mounted() {
    try {
      if (!this.isMapsAPIScriptInjected()) {
        await this.injectMapsAPIScript();
      }

      const printers = await this.getPrinters();
      this.initMap(printers);

    } catch (error) {
      this.$store.dispatch("addNotification", {
        text: error.response.data.message,
        time: 6,
        color: "danger"
      });
    }
  }
};
</script>
